
import AttendanceChart from '@/costumComponents/classe/assiduite/attendanceChart.vue'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import KTDatatable from '@/components/kt-datatable/KTDatatable.vue'
import { MenuComponent } from '@/assets/ts/components'
import ApiService from '@/core/services/ApiService'
import moment from 'moment'
import attendance from './attendance.vue'
import { useI18n } from 'vue-i18n'
import JwtService from '@/core/services/JwtService'

interface Attendance {
  Retard: number
  Absence: number
}

interface IStudent {
  _id: string
  firstName: string
  lastName: string
  classRoom: string
}

interface ISchedule {
  day: string
  duration: number
  ends: string
  subject: {
    name: string
    _id: string
    status: string
  }
  time: string
  _id: string
  name: string
}

export default defineComponent({
  name: 'student-attendance',
  components: {
    KTDatatable,
    attendance,
    AttendanceChart,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const store = useStore()

    const student = ref<IStudent>({
      _id: '',
      firstName: '',
      lastName: '',
      classRoom: '',
    })

    const attendances = ref()

    // today's schedule
    const scheduleData = ref<ISchedule[]>([])

    const search = ref<string>('')

    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + '/')

    const tableHeader = ref([
      { name: t('course.subject'), key: 'name', sortable: true },
      { name: t('classInfo.attendance'), key: 'attendance', sortable: true },
      { name: '', key: 'd', sortable: false },
    ])

    ApiService.setHeader()

    onMounted(() => {
      MenuComponent.reinitialization()
    })

    const filtredSchedule = computed(() => {
      if (search.value) {
        return scheduleData.value.filter((s: ISchedule) => {
          const name = s.name ? s.name : s.subject.name
          return name.includes(search.value.toString())
        })
      }
      return scheduleData.value
    })

    const loading = ref(true)
    onMounted(async () => {
      // get the student data
      const sy = window.localStorage.getItem('activeSchoolarYear')
      await ApiService.post('students/filter', {
        query: { _id: route.params.id },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: '$schoolarYearsHistory.' + sy,
                  to: 'objectId',
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: 'classrooms',
              localField: 'classRoom',
              foreignField: '_id',
              as: 'classRoom',
            },
          },
          {
            $lookup: {
              from: 'parents',
              localField: 'parent',
              foreignField: '_id',
              as: 'parent',
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ['$classRoom._id', 0],
              },
              classRoomName: {
                $arrayElemAt: ['$classRoom.name', 0],
              },
              father: {
                $arrayElemAt: ['$parent.father', 0],
              },
              mother: {
                $arrayElemAt: ['$parent.mother', 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data.length > 0) student.value = data[0]
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false))
      // get the today student schedules

      const query = {
        teacher: store.getters.currentUser._id,
        classeRoom: student.value.classRoom,
        day: (moment().isoWeekday() - 1).toString(),
        status: { $ne: 'inactive' },
      }

      ApiService.post('schedules/filter', {
        query: query,
        aggregation: [
          {
            $lookup: {
              from: 'subjects',
              localField: 'subject',
              foreignField: '_id',
              as: 'subject',
            },
          },
          {
            $lookup: {
              from: 'modules',
              localField: 'module',
              foreignField: '_id',
              as: 'module',
            },
          },
          {
            $lookup: {
              from: 'modulespecifics',
              localField: 'moduleSpecific',
              foreignField: '_id',
              as: 'moduleSpecific',
            },
          },
          {
            $project: {
              _id: 1,
              time: 1,
              day: 1,
              subject: {
                $let: {
                  vars: {
                    subjectVar: {
                      $arrayElemAt: ['$subject', 0],
                    },
                  },
                  in: {
                    name: '$$subjectVar.name',
                    _id: '$$subjectVar._id',
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ['$module', 0],
                    },
                  },
                  in: {
                    name: '$$moduleVar.name',
                    _id: '$$moduleVar._id',
                    status: '$$moduleVar.status',
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ['$moduleSpecific', 0],
                    },
                  },
                  in: {
                    name: '$$moduleSpecificVar.name',
                    _id: '$$moduleSpecificVar._id',
                    status: '$$moduleSpecificVar.status',
                  },
                },
              },
              ends: 1,
              type: 1,
              duration: 1,
              name: 1,
              includedSubjects: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          scheduleData.value = []
          data.map((s) => {
            if (!scheduleData.value.find((d) => d._id == s._id)) {
              if (s.type == 'MS') s.subject = s.moduleSpecific
              else if (s.type == 'MP') s.subject = s.module
              scheduleData.value.push(s)
            }
          })

          scheduleData.value = scheduleData.value.filter(
            (d) => d.subject.status !== 'inactive'
          )
        })
        .catch((e) => console.log(e))

      ApiService.post('attendance/filter', {
        query: {
          student: route.params.id,
          schoolarYear: sy,
        },
      }).then(({ data }) => {
        const todayDate = moment().format('YYYY-MM-DD')
        const tomorrowDate = moment().add(1, 'd').format('YYYY-MM-DD')

        attendances.value = data.filter((att) => {
          return (
            att.schedule &&
            att.date &&
            moment(att.date).isBetween(todayDate, tomorrowDate, undefined, '[]')
          )
        })

        attendance.value = data.filter((att) => {
          attendanceData.value[att.type]++
          return (
            att.schedule && att.schedule.classRoomId == student.value.classRoom
          )
        })
        loadedAttendance.value = true
      })
    })

    const attendance = ref([])
    const loadedAttendance = ref<boolean>(false)

    const attendanceData = ref<Attendance>({
      Retard: 0,
      Absence: 0,
    })

    return {
      t,
      tableHeader,
      search,
      filtredSchedule,
      apiUrl,
      student,
      moment,
      scheduleData,
      attendance,
      loadedAttendance,
      attendanceData,
      attendances,
    }
  },
})
